<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#65B4BB;"
      d="M420.948,39.686v432.627c0,21.919-17.767,39.686-39.686,39.686H130.738
	c-21.919,0-39.686-17.767-39.686-39.686V39.686C91.052,17.767,108.819,0,130.738,0h250.523C403.181,0,420.948,17.767,420.948,39.686
	z"
    />
    <path
      style="fill:#7DC7CB;"
      d="M420.948,39.686v432.627c0,21.919-17.767,39.686-39.686,39.686H230.706L344.377,0h36.884
	C403.181,0,420.948,17.767,420.948,39.686z"
    />
    <rect
      x="91.052"
      y="68.623"
      style="fill:#A0DBFD;"
      width="329.896"
      height="378.167"
    />
    <polygon
      style="fill:#C1E9FD;"
      points="420.948,68.623 420.948,446.79 245.187,446.79 329.145,68.623 "
    />
    <rect
      x="212.697"
      y="24.836"
      style="fill:#57A3A7;"
      width="86.607"
      height="19.104"
    />
    <polygon
      style="fill:#F64B4A;"
      points="223.655,215.357 223.655,243.008 317.737,243.008 317.737,329.449 270.703,329.449 
	270.703,362.054 241.306,362.054 241.306,329.449 194.258,329.449 194.258,300.054 288.343,300.054 288.343,272.403 
	194.258,272.403 194.258,185.962 241.306,185.962 241.306,153.357 270.703,153.357 270.703,185.962 317.737,185.962 
	317.737,215.357 "
    />
    <circle style="fill:#57A3A7;" cx="256" cy="477.956" r="22.289" />
    <g>
      <polygon
        style="fill:#F76A6A;"
        points="264.002,362.054 270.703,331.882 270.703,362.054 	"
      />
      <polygon
        style="fill:#F76A6A;"
        points="317.737,185.962 317.737,215.357 296.57,215.357 303.103,185.962 	"
      />
      <polygon
        style="fill:#F76A6A;"
        points="317.737,243.008 317.737,329.449 271.236,329.449 277.771,300.054 288.343,300.054 
		288.343,272.403 283.909,272.403 290.43,243.008 	"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
